
import { useState } from 'react'
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }


  const clearState = () => setState({ ...initialState })

  const number = (e) =>{
    Swal.fire({
      icon: 'info',
      title: 'Esperamos su llamada',
      html:'<a href="tel:+17863395170">(786) 339-5170</a> ',
     
    })   
  } 
  const correo = (e) =>{
    Swal.fire({
      icon: 'info',
      title: 'Escribenos',
      html:'<a href="mailto:sophiasblinds@gmail.com">sophiasblinds@gmail.com</a> ',
     
    })   
  } 
  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_bmrrobp', 'template_kmxp15q', e.target, 'Q5GpSVtLN-6VETnzD'
      )
      .then(
        (result) => {
          clearState()
          Swal.fire({
            icon: 'success',
            title: 'Gracias!',
            text: 'Su mensaje ha sido enviado, estaremos en contacto pronto 👋',
           
          })          
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Lo sentimos,',
            text: 'Su mensaje no ha sido enviado, por favor llame al 786-339-5170.',
           
          })
         
        }
      )
  }
  return (
    <div>
      <div id='contact'>
     
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Solicitar un Presupuesto</h2>
                <p>
                Complete el siguiente formulario para enviarnos un correo electrónico y lo contáctaremos tan pronto sea posible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                      value={name}
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                     value={email}
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    value={message}
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar Mensaje
                </button>
              </form>
              <div className='col-md-3 col-md-1 contact-info'>
            <div className='contact-item'>
              <h2 className='section-title'></h2>
              <p>
              <div className= 'map-responsive' >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.429498451998!2d-80.37494848510877!3d25.590640583714716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c3cf40c0704b%3A0x353db70e48af3759!2sSophia&#39;s%20Blinds!5e0!3m2!1sen!2sus!4v1652114905675!5m2!1sen!2sus" width="300" height="400" style={{border:0}} allowfullscreen="" aria-hidden="false" tabIndex="0"></iframe>
             </div>
              </p>
              <a href="https://www.google.com/maps/dir//10715+SW+190th+St+%2335,+Cutler+Bay,+FL+33157/@25.5914091,-80.3669227,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88d9c47814ee12e5:0x4313da2831624d14!2m2!1d-80.364734!2d25.5914043!3e0" target="_blank">
              <button type='submit' className='btn btn-custom btn-lg'>
                Dirección
                </button>
                </a>
                <p></p>
            </div> 
          </div>
            </div>
            
          </div>

         


          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={correo}>
                      <i className='fa fa-envelope-o'></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={ number} >
                      <i className='fa fa-phone'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
