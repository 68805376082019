 
export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Nuestros Servicios & Productos</h2>
          <p>
            Contamos con una variedad de servicios a gusto y satisfacción de nuestros clientes.
          </p>
        </div>

        <div className='card-box'>
        <div class="box">
    <h1 class="title">CORTINAS</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">Fábricamos a mano los siguientes tipos de cortinas:
    <br/>
    <ul>
    <li>✓ Zebra Blinds</li>
    <li>✓ Roller Shades (Screen, Translucidos)</li>
    <li>✓ Blackouts (Total oscuridad de la habitación)</li>
    </ul>
    </p>
  </div>

  <div class="box">
    <h1 class="title">TRADICIONALES</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">No fábricamos, pero si brindamos el servicio de venta e instalación de cortinas tradicionales, como:
    <ul>
    <li>✓ Cortinas Verticales</li>
    <li>✓ Cortinas Horizontales</li>
    </ul>
     </p>
  </div>

  <div class="box">
    <h1 class="title">MOTORIZADAS</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">También fábricamos e instalamos las cortinas motorizadas, trabajamos con las marcas:
    <ul>
    <li>✓ ARA</li>
    <li>✓ SOMFY</li>
    <li>✓ ALPHA MOTORS</li>
    </ul>
    </p>
  </div>

  <div class="box">
    <h1 class="title">TECNOLOGÍA</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">Ofrecemos el servicio de conectarlas con tecnologías como:
    <ul>
    <li>✓ Alexa</li>
    <li>✓ Google Home</li>
    <li>✓ Aplicación al teléfono</li>
    </ul>
     </p>
  </div>

  <div class="box">
    <h1 class="title">CUIDADOS</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">Contamos con asesoría a la hora de la limpieza, cuidados y usos de todo tipo de cortinas. No dejes que tus cortinas pierdan vida útil por falta de limpieza.
     </p>
  </div>
  <div class="box">
    <h1 class="title">DESCUENTOS</h1>
    <hr/>
    <br/>
    <br/>
    <p class="text">Si perteneces a una de estas fuerzas, calificarías para la promoción de <b>10% OFF</b>:
     <ul>
    <li>✓ Policía Activo</li>
    <li>✓ Policía Retirados</li>
    <li>✓ Militares Activos</li>
    <li>✓ Militares Retirados</li>
    </ul>
     </p>
  </div>
        </div>
      </div>
    </div>
  )
}
  

